import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/util';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Config } from '@core/config/config';
import { HttpService, ObjectComparatorService } from '@core/services';
import { SiteAuditHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  isValidForm,
  momentizeObjectDates,
  toggleModal,
} from '@core/utils/methods';
import { SITE_AUDIT_MOMENTIZE_FIELDS, UPLOADER_PROPS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  FormProps,
  SiteAuditCablesData,
  SiteAuditRaycapsData,
} from '@models/interfaces';
import { SiteInformationType } from '@models/types';
import {
  CustomTable,
  DeleteModal,
  DragAndDropUploader,
  NotificationsLoader,
  Panel,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, SiteAuditActions } from '@store/actions';
import { SiteAuditSelectors } from '@store/selectors';
import { SiteInformationForm } from './components/forms';
import {
  getSiteAuditCablesColumns,
  getSiteAuditRaycapsColumns,
  SITE_AUDIT_CABLES_DEFAULT_VALUE,
  SITE_AUDIT_RAYCAPS_DEFAULT_VALUE,
} from './models/constants';
import { SiteInformationFormRefType } from './models/types';
import { CableModal, RaycapModal } from './components';

import './styles.scss';

export const SiteAuditForm: FC<FormProps> = ({
  isEditing = true,
  toggleEditing,
  permissions: { attachmentAccess, tableAccess, siteInformationAuditFields },
}: PropsWithChildren<FormProps>) => {
  const dispatch = useDispatch();

  const [form] = useForm();

  const siteAuditService = useMemo(
    () => HttpService.getHttpRequests(SiteAuditHttpService),
    [],
  );

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const siteInformationRef = useRef<SiteInformationFormRefType>(null);

  const {
    dropdownsOptions,
    siteAuditData,
    siteAuditSiteInfo,
    cablesTableData,
    raycapsTableData,
    originalCablesTableData,
    originalRaycapsTableData,
  } = useSelector(SiteAuditSelectors.getSiteAuditFormData);
  const { id: currentProjectId, projectType } = useSelector(
    SiteAuditSelectors.getProjectInfo('id', 'projectType'),
  );
  const siteAuditFetching = useSelector(SiteAuditSelectors.isFetching);

  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [cableModalVisible, setCableModalVisible] = useState<boolean>(false);
  const [raycapModalVisible, setRaycapModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedCable, setSelectedCable] =
    useState<Undefinable<SiteAuditCablesData>>(undefined);
  const [selectedRaycap, setSelectedRaycap] = useState<SiteAuditRaycapsData>(
    SITE_AUDIT_RAYCAPS_DEFAULT_VALUE,
  );
  const [selectedItem, setSelectedItem] = useState<SiteAuditRaycapsData>();
  const [iframeName, setIframeName] = useState<string>();
  const [isSiteInfoCompare, setIsSiteInfoCompare] = useState<boolean>(true);

  const isDataNotUpdated =
    isSiteInfoCompare &&
    ObjectComparatorService.arraysCompare(
      cablesTableData,
      originalCablesTableData,
    ) &&
    ObjectComparatorService.arraysCompare(
      raycapsTableData,
      originalRaycapsTableData,
    ) &&
    siteAuditData?.fileName === iframeName;

  const toggleAddAndEditCableModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setCableModalVisible(!cableModalVisible);

    if (cableModalVisible) {
      setSelectedCable(undefined);
    }
  };
  const toggleAddAndEditRaycapModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setRaycapModalVisible(!raycapModalVisible);

    if (raycapModalVisible) {
      setSelectedRaycap({ ...SITE_AUDIT_RAYCAPS_DEFAULT_VALUE });
    }
  };
  const selectCurrentCable = (
    currentCable: SiteAuditCablesData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedCable(currentCable);
    setSelectedItem(currentCable);
    setter(!prevState);
  };
  const selectCurrentRaycap = (
    currentRaycap: SiteAuditRaycapsData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedRaycap(currentRaycap);
    setSelectedItem(currentRaycap);
    setter(!prevState);
  };
  const toggleViewMode = (): void => {
    iframeRef.current?.contentWindow?.postMessage(
      `isEditing=${isEditing}`,
      '*',
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      ...siteAuditSiteInfo,
    });
  }, []);

  useEffect(() => {
    const { siteAuditNote, ...siteAuditRestData } = siteAuditData;

    form.setFieldsValue({
      ...momentizeObjectDates(siteAuditRestData, SITE_AUDIT_MOMENTIZE_FIELDS),
    });

    if (Object.keys(siteAuditRestData).length !== 0) {
      siteInformationRef.current?.setUnknownSwitch();
    } else {
      siteInformationRef.current?.changeUnknownSwitchState(false);
      form.setFieldsValue({
        unknownPowerPlantType: undefined,
        powerPlantType: undefined,
      });
    }

    if (siteAuditRestData?.fileName) {
      setIframeName(siteAuditRestData?.fileName);
    }
  }, [siteAuditData]);

  useEffect(() => {
    toggleViewMode();
  }, [isEditing]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDataNotUpdated));
  }, [
    isSiteInfoCompare,
    cablesTableData,
    originalCablesTableData,
    raycapsTableData,
    originalRaycapsTableData,
    iframeName,
    siteAuditData,
  ]);

  const giveEquipmentsWithIds = (
    equipments: SiteAuditRaycapsData[],
  ): Omit<SiteAuditRaycapsData, 'equipment'>[] =>
    equipments?.map((equipmentItem: SiteAuditRaycapsData) => {
      const { equipment, generatedId, manufacturer, ...rest } = equipmentItem;

      return { ...rest, equipment: equipment.id };
    });

  const onSubmit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        setIsSiteInfoCompare(true);

        dispatch(CommonActions.setHasUnsubmittedData.done(false));

        await siteAuditService.updateSiteAudit(currentProjectId!, {
          ...(siteInformationRef.current?.onSubmit() as SiteInformationType),
          cables: giveEquipmentsWithIds(cablesTableData),
          raycaps: giveEquipmentsWithIds(raycapsTableData),
          fileName: iframeName,
        });

        NotificationsLoader.notificationSuccess(
          `Site Audit info has been updated!`,
        );

        toggleEditing?.();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    form.setFieldsValue({
      ...siteAuditSiteInfo,
      ...momentizeObjectDates(siteAuditData, [
        'scheduledSiteWalkDate',
        'siteWalkCompleteDate',
        'savedToFileDate',
      ]),
    });
    siteInformationRef.current?.setUnknownSwitch();

    dispatch(SiteAuditActions.setCablesAction.done(originalCablesTableData));
    dispatch(SiteAuditActions.setRaycapsAction.done(originalRaycapsTableData));

    setIframeName(siteAuditData?.fileName || undefined);

    setIsSiteInfoCompare(true);

    toggleEditing?.();
  };

  const onDeleteTableItem = (): void => {
    if (selectedItem?.generatedId?.includes('Raycap')) {
      dispatch(
        SiteAuditActions.deleteRaycapItemAction.done({
          generatedId: selectedItem?.generatedId || '',
        }),
      );
    } else {
      dispatch(
        SiteAuditActions.deleteCableItemAction.done({
          generatedId: selectedItem?.generatedId || '',
        }),
      );
    }

    setSelectedCable({ ...SITE_AUDIT_CABLES_DEFAULT_VALUE });
    setSelectedRaycap({ ...SITE_AUDIT_RAYCAPS_DEFAULT_VALUE });
    setDeleteModalVisible(!deleteModalVisible);
  };
  const mainSpan = isEditing ? 24 : 12;
  const equipmentSpan = isEditing ? 12 : 24;

  const buttonsDisabled = siteAuditFetching || isDataNotUpdated;

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-site-audit-form">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
        })}
      >
        <Row>
          <Col span={mainSpan}>
            <Panel
              header="Site Information"
              className="prov-site-audit-form__information"
              collapsible
            >
              <SiteInformationForm
                ref={siteInformationRef}
                form={form}
                data={{ ...siteAuditData, site: siteAuditSiteInfo }}
                projectType={projectType!}
                isEditing={isEditing}
                permissions={siteInformationAuditFields}
                dropdownsOptions={dropdownsOptions}
                setIsSiteInfoCompare={setIsSiteInfoCompare}
              />
            </Panel>
            <Row
              justify="space-between"
              className="prov-site-audit-form__equipment"
            >
              <Col span={equipmentSpan}>
                <Panel header="Cable Information">
                  <CustomTable
                    dataSource={cablesTableData}
                    columns={getSiteAuditCablesColumns(
                      (cable: SiteAuditCablesData): void =>
                        selectCurrentCable(
                          cable,
                          () => toggleAddAndEditCableModal(ModalMainTypes.Edit),
                          cableModalVisible,
                        ),
                      (cable: SiteAuditCablesData): void =>
                        selectCurrentCable(
                          cable,
                          setDeleteModalVisible,
                          deleteModalVisible,
                        ),
                      isEditing && tableAccess,
                    )}
                  />
                  <CableModal
                    visible={cableModalVisible}
                    modalType={currentModalType}
                    toggleModal={toggleAddAndEditCableModal}
                    itemValue={selectedCable}
                  />
                  {isEditing && !!tableAccess && (
                    <PrimaryButton
                      icon="+"
                      title="Add"
                      className="prov-site-audit-form__equipment__action-button"
                      onClick={(): void =>
                        toggleAddAndEditCableModal(ModalMainTypes.Add)
                      }
                    />
                  )}
                </Panel>
              </Col>
              <Col span={equipmentSpan}>
                <Panel header="Raycap Information">
                  <CustomTable
                    dataSource={raycapsTableData}
                    columns={getSiteAuditRaycapsColumns(
                      (raycap: SiteAuditRaycapsData): void =>
                        selectCurrentRaycap(
                          raycap,
                          () =>
                            toggleAddAndEditRaycapModal(ModalMainTypes.Edit),
                          raycapModalVisible,
                        ),
                      (raycap: SiteAuditRaycapsData): void =>
                        selectCurrentRaycap(
                          raycap,
                          setDeleteModalVisible,
                          deleteModalVisible,
                        ),
                      isEditing && tableAccess,
                    )}
                  />
                  <RaycapModal
                    visible={raycapModalVisible}
                    modalType={currentModalType}
                    toggleModal={toggleAddAndEditRaycapModal}
                    itemValue={selectedRaycap}
                  />
                  {isEditing && !!tableAccess && (
                    <PrimaryButton
                      icon="+"
                      title="Add"
                      className="prov-site-audit-form__equipment__action-button"
                      onClick={(): void =>
                        toggleAddAndEditRaycapModal(ModalMainTypes.Add)
                      }
                    />
                  )}
                </Panel>
                <DeleteModal
                  onOk={onDeleteTableItem}
                  onCancel={toggleModal(setDeleteModalVisible)}
                  visible={deleteModalVisible}
                />
              </Col>
            </Row>
          </Col>
          <Col span={mainSpan}>
            {iframeName && (
              <div
                className={classNames('pdf-wrap', {
                  'pdf-wrap__not-edit': !isEditing,
                })}
              >
                {!isEditing && (
                  <div className="pdf-wrap__header">Attachments</div>
                )}
                <iframe
                  ref={iframeRef}
                  className={classNames('prov-site-audit-form__pdf-window', {
                    'prov-site-audit-form__pdf-window_edit': isEditing,
                  })}
                  title="file"
                  src={`${
                    Config.get().connectionConfig.qoppa
                  }/qnotes.html?filePath=${iframeName}&isEditing=${isEditing}`}
                />
              </div>
            )}
            {(!iframeName || (isEditing && iframeName)) && (
              <Panel
                className={classNames({
                  'attachments-header': !isEditing,
                })}
                header="Attachments"
              >
                <DragAndDropUploader
                  acceptedTypes={['pdf']}
                  disabled={!isEditing || !attachmentAccess}
                  customRequest={async ({
                    file,
                  }: RcCustomRequestOptions): Promise<void> => {
                    const notification = new NotificationsLoader({
                      description: 'File is uploading',
                      message: 'Please wait',
                    });

                    try {
                      const req = await siteAuditService.uploadSiteAuditPDF(
                        file,
                      );

                      setIframeName(req);
                    } catch (e) {
                      const error = e as AxiosError<{ message: string }>;

                      setIframeName(undefined);
                      console.error(error.response!.data.message);
                    }
                    notification.close();
                  }}
                  {...UPLOADER_PROPS}
                />
              </Panel>
            )}
          </Col>
        </Row>
      </div>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              type="default"
              disabled={buttonsDisabled}
              onClick={onCancel}
            />
          </div>
        </Row>
      )}
    </div>
  );
};
